.base-background {
    width: 100%;
    height: 100vh;
    background-image: url('./assets/background-gradient.svg');
    background-size: cover;
    display: flex;
    flex-direction: column;
}

.starfield {
    background-image: url('./assets/starfield.svg');
    background-size: cover;
    opacity: 0.5;
}