.posterBase {
    background-image: url('../assets/content-frame.svg');
    background-repeat: no-repeat;
    margin-left: auto;
    margin-right: auto;
    transition: .7s;
}

.posterBase:hover {
    transform: rotate( -7deg ) scale( 1.2 );
    cursor: pointer;
}

.paint-brush {
    background-image: url('../assets/paint-brush.svg');
    background-repeat: no-repeat;
    margin-left: auto;
    margin-right: auto;
}

.cake {
    background-image: url('../assets/cake.svg');
    background-repeat: no-repeat;
    margin-left: auto;
    margin-right: auto;
}

.whippet {
    background-image: url('../assets/whippet.svg');
    background-repeat: no-repeat;
    margin-left: auto;
    margin-right: auto;
}

.dialogTitle {
    font-family: 'PT Sans';
    font-weight: bold;
}

.dialogContent {
    font-family: 'PT Sans';
}