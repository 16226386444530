.drive-in-screen {
    background-image: url('../assets/drive-in-screen.svg');
    position: absolute;
    bottom: 0;
    left: 0;
}

.drive-in-sign {
    background-image: url('../assets/drive-in-sign.svg');
    position: absolute;
    margin-left: auto;
    margin-right: 9%;
    right: 0;
    left: 0;
    bottom: 0;
}

.car1 {
    background-image: url('../assets/car.svg');
    position: absolute;
    margin-left: 10%;
    left: 0;
    bottom: 0;
}

.car2 {
    background-image: url('../assets/car.svg');
    position: absolute;
    margin-left: auto;
    margin-right: -80px;
    left: 0;
    bottom: 0;
    right: 0;
}

.mountain-left {
    background-image: url('../assets/mountain-left.svg');
    position: absolute;
    left: 0;
    bottom: 0;
}

.mountain-center {
    background-image: url('../assets/mountain-center.svg');
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    bottom: 0;
}

.mountain-right {
    background-image: url('../assets/mountain-right.svg');
    position: absolute;
    margin-left: auto;
    right: 0;
    bottom: 0;
}