.title-bar {
    background-image: url('../assets/title-bar.svg');
    background-repeat: repeat-x;
    height: 40px;
    width: 100%;
    padding-right: 20px;
    padding-top: 2px;
}

.title-text {
    background-image: url('../assets/logo-text.svg');
    background-repeat: no-repeat;
    height: 80px;
}

.title-left {
    background-image: url('../assets/title-left.svg');
    background-repeat: no-repeat;
    height: 80px;
}

.title-right {
    background-image: url('../assets/title-right.svg');
    background-repeat: no-repeat;
    height: 80px;
}

.contactus {
    float: right;
}